import { useQuery } from 'villus'

import { ProductSubSelectKey } from '@/components/productNavigator/mobile/ProductPanel.type'
import { type DeviceType, ProductNavigationDocument } from '@/graphql/generated_villus'

import { getDeviceMenu } from './deviceMenu'
import { getShopifyProducts, getShopifyProductsForDevice } from './products'
import {
  type Devices,
  type ProductList,
  type ProductNavigation,
  type ProductNavigationItem,
} from './types'

const cacheDevices = new Map<string, Devices>()
export async function getDevices(deviceType: DeviceType | null): Promise<Devices | null> {
  if (deviceType === null) {
    return null
  }

  const cacheItem = cacheDevices.get(deviceType)
  if (cacheItem !== undefined) {
    return cacheItem
  }

  const deviceMenu = await getDeviceMenu(deviceType)
  if (deviceMenu === null) {
    return null
  }
  cacheDevices.set(deviceType, deviceMenu)

  return deviceMenu
}

const cacheProductsForDevice = new Map<string, ProductList>()
export async function getProductsForDevice(
  deviceHandle: null | string,
): Promise<null | ProductList> {
  if (deviceHandle === null) {
    return null
  }
  const cacheItem = cacheProductsForDevice.get(deviceHandle)
  if (cacheItem !== undefined) {
    return cacheItem
  }

  const products = await getShopifyProductsForDevice(deviceHandle)
  if (products === null) {
    return null
  }
  cacheProductsForDevice.set(deviceHandle, products)
  return products
}

const cacheIndependentProducts = new Map<string, ProductList>()
export function getIndependentProducts(
  productSeriesList: readonly string[],
): () => Promise<null | ProductList> {
  const productSeriesKey = productSeriesList.join(',')
  return async () => {
    const cacheItem = cacheIndependentProducts.get(productSeriesKey)
    if (cacheItem !== undefined) {
      return cacheItem
    }

    const products = await getShopifyProducts(productSeriesList)
    if (products === null) {
      return null
    }
    cacheIndependentProducts.set(productSeriesKey, products)
    return products
  }
}

let cacheProductNavigation: null | ProductNavigation = null
export async function getProductNavigation(): Promise<null | ProductNavigation> {
  if (cacheProductNavigation !== null) {
    return cacheProductNavigation
  }
  const productNavigation = new Map<string, ProductNavigationItem>()
  const { data, error } = await useQuery({
    query: ProductNavigationDocument,
  })
  if (data.value === null || error.value !== null) {
    return null
  }
  for (const item of data.value.productNavigation) {
    const getProducts =
      item.deviceType === null
        ? getIndependentProducts(item.productSeriesList)
        : getProductsForDevice
    // TODO(Benson): hard code 未來這些資料等後端有 i18n 之後看要怎麼搞
    const note =
      item.key === 'grip'
        ? {
            briefDescription: 'message.compatibility',
            dialogTitle: 'dialog.title.grip',
            dialogContent: 'dialog.content.grip',
          }
        : null
    productNavigation.set(item.key, {
      type: item.type,
      image: item.image,
      defaultDevice: item.defaultDevice ?? null,
      deviceType: item.deviceType ?? null,
      getProducts,
      note,
      productSeriesList: item.productSeriesList,
      designGroupType: item.bindingDesignGroup.type,
    })
  }

  cacheProductNavigation = productNavigation
  return productNavigation
}

export const productNavigationMappingSubSelects: Record<string, ProductSubSelectKey[]> = {
  case: [
    ProductSubSelectKey.Device,
    ProductSubSelectKey.DeviceColor,
    ProductSubSelectKey.Product,
    ProductSubSelectKey.ProductColor,
  ],
  independent: [ProductSubSelectKey.Product, ProductSubSelectKey.ProductColor],
}

export const defaultProductNavigation = 'phone-case'
