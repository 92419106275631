import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { ref } from 'vue'

export interface ToastOptions {
  content: string
  time: number
}

export const useToast = defineStore('toastStore', () => {
  const toastList = ref<Map<string, ToastOptions>>(new Map())
  function addToast(item: ToastOptions): void {
    const id = uuidv4()
    toastList.value.set(id, item)
    setToastTime(id, item)
  }
  function setToastTime(id: string, item: ToastOptions): void {
    window.setTimeout(() => {
      toastList.value.delete(id)
    }, item.time)
  }

  return {
    addToast,
    toastList,
  }
})
