// 這邊的資料是為了讓 disabled 的產品可以有預覽圖出現，因為正常狀況下如果不支援此產品 api 回來的資料應該就不會有產品的資訊，所以才會出現這種需要另外寫的狀況
export const productSeriesThumbnailImages: Record<string, string> = {
  solidsuit:
    'https://cdn.shopify.com/s/files/1/0274/8717/files/navi_solidsuit.png?18001474384591642561',
  'mod-nx':
    'https://cdn.shopify.com/s/files/1/0274/8717/files/dyoc_mod_nx_whole_group.png?v=1669340681',
  'clear-case':
    'https://cdn.shopify.com/s/files/1/0274/8717/files/navi_clear_with_magsafe.png?v=1659252076',
  'grip-mini':
    'https://cdn.shopify.com/s/files/1/0274/8717/products/minisite-STG00250N3-2_200x@2x.png?v=1659062004',
  'grip-max':
    'https://cdn.shopify.com/s/files/1/0274/8717/products/minisite-STG00253N3-2_200x@2x.png?v=1659062004',
  'airpods-case':
    'https://cdn.shopify.com/s/files/1/0274/8717/files/product-card_airpods-case_200x@2x.png?v=54088',
}
