import { currencyUnit } from '@/locale'

import { sentry } from './sentry'

type GA4Event =
  | GA4AddToCartEvent
  | GA4FilterEvent
  | GA4PersonalizationClickEvent
  | GA4PersonalizationServiceSelectionEvent
  | GA4ViewCartEvent

interface GA4AddToCartEvent {
  ecommerce: {
    currency: string
    value: number
  }
  event: 'ga4_add_to_cart'
}

export interface GA4ecommerceItem {
  brand: string
  collabHandle: string
  collabName: string
  designName: string
  designType: 'collab' | 'customized' | 'original'
  device: string
  discount: number
  id: string
  index: number
  originalVariantID: number[]
  price: number
  productLine: string
  productLineName: string
  quantity: number
  title: string
  variantTitle: string
}

// 帶入的文案基本上都會用英文帶入
interface GA4PersonalizationClickEvent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  button_location: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  button_text: string
  event: 'personalization_click'
}

// 首頁底下服務的點擊個人化跳轉會用中文處理
interface GA4PersonalizationServiceSelectionEvent {
  background: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  background_title: string
  event: 'personalization_service_selection'
  service: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  service_title: string
}

interface GA4ViewCartEvent {
  event: 'view_cart'
}

interface GA4FilterEvent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  category_selected: string
  event: 'filter'
  // eslint-disable-next-line @typescript-eslint/naming-convention
  filter_type: 'artist_filter' | 'artist_sorting' | 'artwork_filter' | 'artwork_sorting'
}

export function pushGA4AddToCart(price: number): void {
  const event: GA4AddToCartEvent = {
    event: 'ga4_add_to_cart',
    ecommerce: {
      value: price,
      currency: currencyUnit(),
    },
  }
  pushDataLayer(event)
}

export function pushGA4ButtonClick(buttonKey: string): void {
  const location =
    window.location.pathname === '/' ? 'home' : window.location.pathname.replace('/', '')
  const event: GA4PersonalizationClickEvent = {
    event: 'personalization_click',
    button_text: buttonKey,
    button_location: location,
  }
  pushDataLayer(event)
}

export function pushGA4ServiceSelection({
  service,
  serviceTitle,
  background,
  backgroundTitle,
}: {
  background: string
  backgroundTitle: string
  service: string
  serviceTitle: string
}): void {
  const event: GA4PersonalizationServiceSelectionEvent = {
    event: 'personalization_service_selection',
    service,
    service_title: serviceTitle,
    background,
    background_title: backgroundTitle,
  }
  pushDataLayer(event)
}

export function pushGA4ViewCart(): void {
  const event: GA4ViewCartEvent = {
    event: 'view_cart',
  }
  pushDataLayer(event)
}

export function pushGA4Filter(
  filterType: GA4FilterEvent['filter_type'],
  categorySelected: string,
): void {
  const event: GA4FilterEvent = {
    event: 'filter',
    filter_type: filterType,
    category_selected: categorySelected,
  }
  pushDataLayer(event)
}

declare global {
  interface Window {
    dataLayer?: GA4Event[]
  }
}

function pushDataLayer(event: GA4Event): void {
  if (!('dataLayer' in window)) {
    window.dataLayer = []
  }
  if (Array.isArray(window.dataLayer)) {
    window.dataLayer.push(event)
  } else {
    sentry.error('dataLayer is not Array', {
      event,
    })
  }
}
