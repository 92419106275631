import * as cookie from 'cookie'

import { type Locale, type ShopifyConfig, shopifyTokenList } from '@/locale'

export enum CookieKey {
  CheckoutId = 'checkoutId',
  DeviceColor = 'deviceColor',
  DeviceHandle = 'deviceHandle',
  ProductColor = 'productColor',
  ProductHandle = 'productHandle',
  ProductNavigation = 'productNavigation',
}

class CookieService {
  private readonly _domain: string
  private readonly _maxAge = 60 * 60 * 24 * 14
  private readonly _namespace: string

  public constructor(locale: Locale) {
    const shopifyConfig: ShopifyConfig = shopifyTokenList[locale]
    this._domain = window.location.host.includes('localhost')
      ? 'localhost'
      : shopifyConfig.shopDomain.replace('https://', '.')
    this._namespace = shopifyConfig.domainKey
  }

  private get _path(): string {
    return '/'
  }

  public getCheckoutId(): null | string {
    return this._get(CookieKey.CheckoutId)
  }

  public getDeviceColor(prefix: string): null | string {
    return this._get(`${prefix}/${CookieKey.DeviceColor}`)
  }

  public getDeviceHandle(prefix: string): null | string {
    return this._get(`${prefix}/${CookieKey.DeviceHandle}`)
  }

  public getProductColor(prefix: string): null | string {
    return this._get(`${prefix}/${CookieKey.ProductColor}`)
  }

  public getProductHandle(prefix: string): null | string {
    return this._get(`${prefix}/${CookieKey.ProductHandle}`)
  }

  public getProductNavigation(): null | string {
    return this._get(CookieKey.ProductNavigation)
  }

  public setCheckoutId(value: string): void {
    this._set(CookieKey.CheckoutId, value, {
      encode: (value: string): string => encodeURI(value),
    })
  }

  public setDeviceColor(prefix: string, value: string): void {
    this._set(`${prefix}/${CookieKey.DeviceColor}`, value, {
      encode: (value: string): string => encodeURI(value),
    })
  }

  public setDeviceHandle(prefix: string, value: string): void {
    this._set(`${prefix}/${CookieKey.DeviceHandle}`, value, {
      encode: (value: string): string => encodeURI(value),
    })
  }

  public setProductColor(prefix: string, value: string): void {
    this._set(`${prefix}/${CookieKey.ProductColor}`, value, {
      encode: (value: string): string => encodeURI(value),
    })
  }

  public setProductHandle(prefix: string, value: string): void {
    this._set(`${prefix}/${CookieKey.ProductHandle}`, value, {
      encode: (value: string): string => encodeURI(value),
    })
  }

  public setProductNavigation(value: string): void {
    this._set(CookieKey.ProductNavigation, value, {
      encode: (value: string): string => encodeURI(value),
    })
  }

  private _get(name: string): null | string {
    const cookies = cookie.parse(document.cookie)
    return cookies[`${this._namespace}/${name}`] ?? null
  }

  private _set(name: string, value: string, options: cookie.CookieSerializeOptions = {}): void {
    if (value === '') {
      return
    }

    document.cookie = cookie.serialize(`${this._namespace}/${name}`, value, {
      domain: this._domain,
      maxAge: this._maxAge,
      path: this._path,
      ...options,
    })
  }
}

export default CookieService
