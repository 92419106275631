import { captureException } from '@sentry/vue'
import { batch } from '@villus/batch'
import { createClient, dedup, definePlugin } from 'villus'

import { LocalStorage } from '@/utils/storage'

function getGqlUrl(): string {
  const specifyUrl = localStorage.getItem(LocalStorage.ViteGraphqlEndpointURL)
  if (specifyUrl !== null) {
    return specifyUrl
  }
  return import.meta.env.VITE_GRAPHQL_ENDPOINT_URL as string
}

class CombinedError extends Error {}
CombinedError.prototype.name = 'CombinedError'

// docs: https://villus.logaretm.com/guide/plugins/#example---global-error-handler
const sentryReportPlugin = definePlugin(({ operation, afterQuery }) => {
  afterQuery(({ error }, { response }) => {
    const operationContext = {
      query: operation.query,
      type: operation.type,
      variables: operation.variables,
    }
    if ((response?.status ?? 200) >= 500) {
      captureException(error ?? new CombinedError('received 500 response code from API'), {
        contexts: {
          info: {
            description: 'received 500 response code from API',
          },
          operation: operationContext,
        },
      })
    } else if (error !== null) {
      captureException(error, {
        contexts: {
          operation: operationContext,
        },
      })
    }
  })
})

export const villusClient = createClient({
  url: getGqlUrl(),
  cachePolicy: 'network-only',
  use: [sentryReportPlugin, dedup(), batch()],
})
