import { defineStore } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { Route } from '@/router/route'

export function setCssVariable(key: string, value: string): void {
  document.documentElement.style.setProperty(key, value)
}
export const useLayoutStore = defineStore('layout', () => {
  const MOBILE_SIZE = 768
  const mediaType = ref<'desktop' | 'mobile'>()
  const windowWidth = ref(0)
  onMounted(() => {
    window.addEventListener('resize', () => {
      handleWindowSizeChange()
    })
    handleWindowSizeChange()
  })
  function handleWindowSizeChange(): void {
    windowWidth.value = window.innerWidth
    mediaType.value = window.innerWidth >= MOBILE_SIZE ? 'desktop' : 'mobile'
    // setEmptyAreaWidth
    const emptyAreaWidth = Math.max((window.innerWidth - MOBILE_SIZE) / 2, 0)
    setCssVariable('--empty-area-width', `${emptyAreaWidth}px`)
    // setVh
    setCssVariable('--real1vh', `${window.innerHeight / 100}px`)
  }
  const isMobile = computed(() => mediaType.value === 'mobile')
  const isDesktop = computed(() => mediaType.value === 'desktop')

  const route = useRoute()
  const isRwdPage = computed(() => {
    const routes: string[] = [
      Route.ArtistPage,
      Route.GalleryPage,
      Route.ArtistsPage,
      Route.FavoriteArtworkPage,
    ]
    if (route.name === undefined) return false
    const name: string = route.name.toString()
    return routes.includes(name)
  })

  // todo(chili): 這邊可以直接讓 header watch FavoriteArtwork，就不用經過 store 處理
  const animateHeartTriggerSignal = ref(0)
  function animateHeart(): void {
    animateHeartTriggerSignal.value = Date.now()
  }

  return {
    mediaType,
    windowWidth,
    MOBILE_SIZE,
    isMobile,
    isDesktop,
    isRwdPage,
    animateHeartTriggerSignal,
    animateHeart,
  }
})
