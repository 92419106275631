<template>
  <transition-group
    enter-active-class="ease-in-out duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-for="([id, item], index) in toastList.entries()"
      :key="id"
      class="fixed left-1/2 z-50 transform duration-300 -translate-x-1/2"
      :style="{ top: `${index * (TOAST_HEIGHT + TOAST_MARGIN) + 150}px` }"
    >
      <div
        class="m-10px min-w-180px flex items-center justify-center rounded-full bg-primary-100 p-8px shadow-dropdown"
        :style="{ height: `${TOAST_HEIGHT}px` }"
      >
        <p class="whitespace-nowrap content-16-h24-r text-white">
          {{ item.content }}
        </p>
      </div>
    </div>
  </transition-group>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { useToast } from '@/store/toastStore'

const TOAST_HEIGHT = 40
const TOAST_MARGIN = 10

const store = useToast()

const { toastList } = storeToRefs(store)
</script>
