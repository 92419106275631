import {
  isImageBackground,
  isPhotoElement,
  isStickerElement,
  setCachedDataUrl,
  useDesignStore,
} from '@evolutivelabs/amuse-design-canvas'
import { watch } from 'vue'

export function useSpeedUpImageGeneration(): void {
  const designStore = useDesignStore()
  watch(
    () => designStore.design,
    (design) => {
      if (design.isOperating) {
        return
      }
      const background = design.background
      if (background !== null && isImageBackground(background)) {
        void setCachedDataUrl(background.imageUrl)
      }
      const elements = design.elements
      for (const element of elements.values()) {
        if (isStickerElement(element) || isPhotoElement(element)) {
          void setCachedDataUrl(element.imageUrl)
        }
      }
    },
    { immediate: true },
  )
}
